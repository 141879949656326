@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@font-face {
  font-family: Sofia;
  src: url(/static/media/sofia-pro-regular.ba6932f4.ttf);
}

:root {
  --header-border: #334670;
  --body-background: #FDFEFE;
  --text-color: #000;
  --link-color: #543fd7;
  --title-color: #334670;
  --loader: #0A1931;
  --hr-color: #C6C8C9;
}

[data-theme="light"] {
  --header-border: #334670;
  --body-background: #FDFEFE;
  --text-color: #000;
  --link-color: #543fd7;
  --title-color: #334670;
  --loader: #0A1931;
  --hr-color: #C6C8C9;
  --body-color: #F6F6F6;
}

[data-theme="dark"]{
  --header-border: #4FABFF;
  --body-background: #212a2e;
  --text-color: #fff;
  --link-color: #5bc0de;
  --title-color: #fff;
  --loader: #fff;
  --hr-color: #fff;
  --body-color: #1f2a42;
}

body{
  background: var(--body-color);
  font-family: Poppins, sans-serif;
  overflow-x: hidden;
}

input:focus, input:hover, button:hover, button:active{
  outline: none;
}
a, a:hover{
  text-decoration:none;
  color: #000;
  color: var(--text-color);
}
.o1 {
  width: 50px;
  height: 50px;
  background: #FDFEFE;
  background: var(--body-background);
  border-radius: 50%;
  border: 10px solid #FFC947;
  margin-left: 0px;
  margin-right: -50px;
  display: inline-block;
  -webkit-animation: thecodes1 1.8s infinite alternate;
          animation: thecodes1 1.8s infinite alternate;
}
.o2 {
  width: 50px;
  height: 50px;
  background: #FDFEFE;
  background: var(--body-background);
  border-radius: 50%;
  border: 10px solid #0A1931;
  border: 10px solid var(--loader);
  margin: 0 auto;
  display: inline-block;
  -webkit-animation: thecodes2 1s infinite alternate;
          animation: thecodes2 1s infinite alternate;
}
.o3 {
  width: 50px;
  height: 50px;
  background: #FDFEFE;
  background: var(--body-background);
  border-radius: 50%;
  border: 10px solid #0A1931;
  border: 10px solid var(--loader);
  margin-left:-50px;
  display: inline-block;
  -webkit-animation: thecodes3 3s infinite alternate;
          animation: thecodes3 3s infinite alternate;
}
@-webkit-keyframes thecodes1{
  30%{margin-right:-50px;margin-left:0;}
  100%{margin-right:-10px;margin-left:0;}
}
@keyframes thecodes1{
  30%{margin-right:-50px;margin-left:0;}
  100%{margin-right:-10px;margin-left:0;}
}
@-webkit-keyframes thecodes2{
  30%{margin-left:0;}
  100%{margin-right:0;}
}
@keyframes thecodes2{
  30%{margin-left:0;}
  100%{margin-right:0;}
}
@-webkit-keyframes thecodes3{
  30%{margin-left:-50px;}
  100%{margin-left:-10px;}
}
@keyframes thecodes3{
  30%{margin-left:-50px;}
  100%{margin-left:-10px;}
}
.header{
  width: 100%;
  padding: 5px;
  background: #FDFEFE;
  background: var(--body-background);
  margin: 0 auto;
  height: 60px;
  border-bottom: 3px solid #334670;
  border-bottom: 3px solid var(--header-border);
  color: #000;
  color: var(--text-color);
}
.header-left{
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
}
.network-using{
  height: 46px;
  width: 46px;
  border-radius: 50%;
  background: #f7f7f7;
  margin-left: 0;
  border: 3px solid green;
  display: none;
}
.network-using img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.thecodes-text{
  color: #000;
  color: var(--text-color);
  font-size: 30px;
  font-family: Sofia;
}
.thecodes-text:hover, .thecodes-text:active{
  color: #000;
  color: var(--text-color);
}
.thecodes-header{
  height: 40px;
  width: auto;
  margin-top: -7px;
}
.header-menu-show{
  height: 46px;
  width: 46px;
  border-radius:6px;
  position: absolute;
  right: 31px;
  cursor: pointer;
  display: none;
  transition: 700ms;
  text-align: center;
}
.header-menu-show:hover{
  background: rgba(0,0,0,0.05);
}
.header-menu-show .bi{
  font-size: 30px;
  color: #000;
  color: var(--text-color);
}
.header-right{
  display: flex;
  flex-wrap: nowrap;
  text-align: right;
  position: absolute;
  right: 31px;
  transition: 1s;
  margin-top: 3px;
  width: 70%;
}
.header-right .header-menu{
  color: #000;
  color: var(--text-color);
  font-size: 14px;
  padding: 7px;
  transition: 700ms;
  height: 35px;
  -webkit-transform: translateY(12%);
          transform: translateY(12%);
  margin: 0 5px;
  margin-bottom: -5px;
  cursor: pointer;
}
.header-right .header-menu:hover{
  background: rgba(0,0,0,0.05);
  border-radius: 6px;
}
.header-right .header-menu .header-link{
  color: white;
}
.header-search{
  display: none;
}
.search-header{
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  margin-top: 7px;
}
.search-header input{
  height: 100%;
  width: 100%;
  border: 0;
  background: transparent;
  color: #000;
  color: var(--text-color);
}
.search-header button{
  height: 100%;
  width: 50px;
  background: transparent;
  border: 0;
  color: #000;
  color: var(--text-color);
}
.search-header input::-webkit-input-placeholder {
  color: #000;
  color: var(--text-color);
  opacity: 0.5;
}
.summary-div{
  padding: 0 10px;
}
.summary{
  padding: 7px;
  width: 100%;
  border-radius: 7px;
  background: #FDFEFE;
  background: var(--body-background);
  margin-top: 15px;
}
.summary-12{
  padding: 12px;
}
.summary strong{
  color: #334670;
  color: var(--title-color);
}
.title-div{
  width: 100%;
  padding: 6px;
  transition: 700ms;
  height: 60px;
}
.title-div hr{
  width: 80%;
  margin: 0 auto;
  margin-top: 10px;
}
.title-div .title{
  display: inline-block;
  margin-left: 10px;
  margin-top: 5px;
  position: absolute;
  opacity: 0.7;
  font-size: 13px;
  transition: 700ms;
  cursor: default;
  color: #000;
  color: var(--text-color);
}
.title-div .title-value{
  display: inline-block;
  margin-left: 10px;
  margin-top: 20px;
  position: absolute;
  font-size: 23px;
  font-weight: bold;
  transition: 700ms;
  cursor: default;
  color: #000;
  color: var(--text-color);
}
.hide-mobile{
  display: block;
}
.hide-mobile.inline{
  display: inline-block;
}
.content{
  width: 100%;
  border-radius: 5px;
  background: white;
}
.text-hash{
  white-space: nowrap; 
  text-overflow: ellipsis;
  overflow: hidden;
  color: #000;
  color: var(--text-color);
  font-size: 14px;
}
.image-validator {
  height: 19px;
  width: auto;
  border-radius: 5px;
  margin-right: 10px;
}
.content-table-link{
  color: #543fd7;
  color: var(--link-color);
}
.content-table-link:hover, .content-table-link:focus, .content-table-link:active{
  color: #543fd7;
  color: var(--link-color);
}
.content-table-text{
  font-size: 14px;
  word-wrap: break-word;
  color: #000;
  color: var(--text-color);
}
.content-table-title{
  font-weight: bold;
  color: #334670;
  color: var(--title-color);
  font-size: 14px;
}
.overflow-custom::-webkit-scrollbar {
  width: 4px;
}
.overflow-custom::-webkit-scrollbar-track {
  background: #FDFEFE;
  background: var(--body-background); 
}
.overflow-custom::-webkit-scrollbar-thumb {
  background: rgba(23, 75, 196, 0.5);
  border-radius:100px;
}
.uptime-container{
  display: flex;
  flex-wrap: wrap;
}
.uptime-light{
  width: 18px;
  margin: 1.5px;
  text-align: center;
  height: 18px;
  font-size: 30px;
  border-radius:2px;
}
.pie-chart{
  margin-top:auto;
  margin-bottom:auto;
  margin-right: auto;
  margin-left: auto;
}
.dropdown-earlier{
  margin-top: 0px;
}
.dropdown-button::after{
  display: none;
}
.dropdown-button-token{
  background: transparent;
  padding: 0;
  border: 0;
  color:#000;
  font-size: 15px;
}
.dropdown-button-token:hover, .dropdown-button-token:focus{
  background: transparent !important;
  outline: none !important;
  box-shadow: none !important;
}
.dropdown-menu-token{
  width: inherit !important;
  max-width: 100%;
  z-index: 1;
}
.dropdown-item{
  transition: 700ms;
}
.switch {
  display: inline-block;
  height: 19px;
  position: absolute;
  width: 45px;
  margin-top: 10px;
}
.switch .checkbox {
  display:none;
}
.slider {
  background-color: #FDFEFE;
  background-color: var(--body-background);
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: .4s;
}
.slider:before {
  background-color: #FDFEFE;
  background-color: var(--body-background);
  bottom: 4px;
  content: "";
  height: 11px;
  left: 4px;
  position: absolute;
  transition: .4s;
  width: 11px;
}
.checkbox:checked + .slider {
  background-color: #66bb6a;
}
.checkbox:checked + .slider:before {
  -webkit-transform: translateX(26px);
          transform: translateX(26px);
}
.slider.round {
  border-radius: 30px;
}
.slider.round:before {
  border-radius: 50%;
}
.tab-div{
  width: 100%;
  background:transparent;
  display: inline-block;
  background: #FDFEFE;
  background: var(--body-background);
}
.tab-button{
  padding: 15px;
  width: 50%;
  background: #FDFEFE;
  background: var(--body-background);
  font-weight: bold;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  border-style: solid;
  border-color: #174bc4;
  font-size: 14px;
  color: #000;
  color: var(--text-color);
}
.connected-div{
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
}
.connected-line {
  height: 3px;
  background: #18bc9c;
  width: 100%;
  margin-top: 21px;
  border-radius: 20px;
}
.connected-line:after {
  float: right;
}
.connected-div .connected-text{
  width: 160px;
  padding: 6px;
  height: 45px;
  text-align: center;
  border-radius: 50px;
  background: #174bc4;
  font-size: 15px;
  color: white;
  border: 5px solid #f7f7f7;
}

.footer-container{
  padding: 10px 25px;
  background-color: #FDFEFE;
  background-color: var(--body-background);
  margin-top: 20px;
}
.footer-social{
  text-align: center;
  padding-top: 16px;
}
.footer-copyright{
  text-align: right;
  padding-top: 16px;
}
.footer-social a, .footer-copyright p{
  color: #000;
  color: var(--text-color);
  font-size: 15px;
  transition: 700ms;
  display: inline-block;
  margin-right: 5px;
  margin-left: 5px;
}
#copy-text{
  cursor: pointer;
}
.no-margin-text{
  margin-top: -10px;
}
.small-text{
  font-size: 13px;
  color: #000;
  color: var(--text-color);
}
.normal-text{
  font-size: 14px;
  color: #000;
  color: var(--text-color);
}
.no-margin-hr{
  margin-top: 0;
}
.loading-div{
  text-align: center;
  margin: 0 auto;
  margin-top: 275px;
  background: #FDFEFE;
  background: var(--body-background);
}
.message-div{
  height: 90vh;
  overflow-y: auto;
}
.no-margin-text{
  margin-top: 0px;
  margin-bottom: 0px;
}
.show-qr{
  font-size: 13px;
  width: 100%;
  text-align: center;
  background: rgba(0,0,0,0.1);
  cursor: pointer;
  border:none;
}
.header-mode{
  margin-top: 15px;
  margin-left: 15px;
  cursor: pointer;
}
.p-title{
  font-size: 14px;
  margin-top: 0;
  margin-bottom: 0;
}
.token-list{
  height: 111px;
  max-height: 111px;
  overflow-y: auto;
  overflow-x: hidden;
}
.circular-div{
  width: 130px;
  height: 130px;
  margin: 0 auto;
  padding: 10px;
}
.circular-div p{
  font-size: 11px;
  margin-bottom: 0;
  color: #000;
  color: var(--text-color);
}
.circular-div strong{
  font-size: 15px;
}
.grid-overlay{
  position: absolute;
  top: 0;
  width: 100%;
}
.margin-auto{
  margin: 0 auto;
}
.capitalize-text{
  text-transform: capitalize;
}
.balance-text{
  margin-bottom: -15px;
  font-size: 13px;
  color: #000;
  color: var(--text-color);
}
.hr-no-margin-top{
  margin-top: -10px;
}
.background-block-validator{
  background: rgba(0,0,0,0.04);
}
.title-text{
  font-size: 17px;
}
.txs-block{
  max-height: 435px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 10px;
}
.validator-block{
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}
.grid-icon{
  opacity: 0.7;
  font-size: 13px;
  cursor: pointer;
  margin-left: 10px;
}
hr, .vr{
  background: #C6C8C9;
  background: var(--hr-color);
  border-color: #C6C8C9;
  border-color: var(--hr-color);
}
.button-pagination-right, .button-pagination-left{
  color: #000;
  color: var(--text-color);
  background: var(--body-color);
}
.button-pagination-right:hover, .button-pagination-right:active, .button-pagination-left:hover, .button-pagination-left:active{
  color: #000;
  color: var(--text-color);
}
.button-pagination-right{
  float: right;
}
.footer-div{
  background: var(--body-color);
  border-top: 3px solid #18bc9c;
  color: #000;
  color: var(--text-color);
  width: 100%;
  padding: 10px 20px;
  margin-top: 20px;
}
.footer-div img{
  height: 45px;
  width: auto;
  display: inline-block;
}
.footer-div p{
  display: inline-block;
  font-family: Poppins, sans-serif;
  font-size: 13px;
  margin: 13px;
}
.footer-div .footer-social{
  float: right;
  margin-top: -17px;
  font-size: 13px;
}
.footer-div .footer-social p{
  display: inline-block;
  font-family: Poppins, sans-serif;
  font-size: 13px;
  margin: 13px 7px;
}
.footer-div .footer-social p a{
  color: #000 !important;
  color: var(--text-color) !important;
  opacity: 0.85;
  font-size: 13px;
}

@media screen and (max-width: 424px) {
  .pie-chart{
    margin-top: 0;
    width: 100px;
    height: auto;
  }
  .footer-social{
    text-align: left;
  }
}

@media screen and (max-width: 767px) {
  .header{
    height: 105px;
  }
  .header-menu-show{
    display: block;
  }
  .header-right{
    display: block;
    width: 60%;
    right: 24px;
    transition: 700ms;
    border-radius: 6px;
    overflow: hidden;
    background: var(--body-color);
    color: #000;
    color: var(--text-color);
    margin-top: 60px;
    height: 0;
    text-align: left;
    z-index: 100;
  }
  .header-menu-show:hover + .header-right{
    height: 75px;
  }
  .search-header{
    display: none;
  }
  .header-right:hover{
    height: 75px;
  }
  .header-search {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    margin-top: 5px;
  }
  .header-search input{
    width: 100%;
    border-radius: 6px 0 0 6px;
    border: 2px solid #FDFEFE;
    border: 2px solid var(--body-background);
    background: #FDFEFE;
    background: var(--body-background);
    color: #000;
    color: var(--text-color);
    padding: 6px;
    font-size: 13px;
  }
  .header-search button{
    width: 50px;
    border-radius: 0 6px 6px 0;
    border: 2px solid #FDFEFE;
    border: 2px solid var(--body-background);
    padding: 6px;
    background: #FDFEFE;
    background: var(--body-background);
    color: #000;
    color: var(--text-color);
    font-size: 13px;
  }
  .hr-right{
    border: 0;
  }
  .hide-mobile{
    display: none;
  }
  .hide-mobile.inline{
    display: none;
  }
  .content-table-text{
    margin-left: 10px;
  }
  .pie-chart{
    margin-top: 0;
    width: 100px;
    height: auto;
  }
  .footer-social{
    text-align: left;
  }
  .message-div{
    height: 100%;
  }
  .footer-div img{
    text-align: center;
    display: block;
    margin: 0 auto;
  }
  .footer-div p{
    display: block;
    text-align: center;
  }
  .footer-div .footer-social{
    float: none;
    text-align: center;
    margin-top: -20px;
  }
}
